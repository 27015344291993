import authService from '../services/auth.service';
import router from '../router/index';

const state = () => ({
    isAuthenticated: false,
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
    userData: {
        id: null,
        is_blocked: false,
        role_title: null,
        isLogged: false,
        name: null,
        surname: null,
        email: null,
        image: null,
        role: null,
        assigned_affiliates: null,
        _refresh: null,
        _token: null,
    },
    userAccessRules: {},
    userRole: null,
});

const getters = {
    getUser: (state) => state.userData,
    getToken: (state) => state.userData._token,
    isAuthenticated: (state) => state.isAuthenticated,
    getUserRole: (state) => state.userData.role,
    getAccessToken: (state) => state.access_token,
    getRefreshToken: (state) => state.refresh_token,
    getUserAccessRules: (state) => state.userAccessRules,
    isSuperAdmin: (state) => state.userData.role && state.userData.role === 'super-admin',
};

const mutations = {
    setUserData(state, payload) {
        for (const key of Object.keys(state.userData)) {
            state.userData[key] = payload.user[key];
        }
        state.userData.role = payload.user.current_roles.toString();
        state.access_token = payload.token;
        localStorage.setItem('access_token', payload.token);
        state.isAuthenticated = true;
    },
    setRefreshUserData(state, payload) {
        for (const key of Object.keys(payload)) {
            state.userData[key] = payload[key];
        }
        state.userData.role = payload.current_roles.toString();
        state.isAuthenticated = true;
    },
    clearUserData(state) {
        state.userData = {
            id: null,
            is_blocked: false,
            role_title: null,
            isLogged: false,
            name: null,
            surname: null,
            email: null,
            image: null,
            role: null,
            assigned_affiliates: null,
            _refresh: null,
            _token: null,
        };
    },
    clearTokens(state) {
        state.refresh_token = null;
        state.access_token = null;
        localStorage.removeItem('access_token');
    },
};

const actions = {
    async logOut({ commit }) {
        try {
            await authService.logout(commit);
            await router.push({ name: 'login' });
        } catch (ex) {
            console.log('cant logout: ' + ex);
        }
    },
    async login({ state, commit, getters }, payload) {
        const user = await authService.login({ state, commit, getters }, payload);
        if (user) commit('setUserData', user);
    },

    async verifyToken({ state, getters, commit }) {
        try {
            const user = await authService.verifyToken({ state, getters, commit });
            if (user) commit('setRefreshUserData', user);
            return user;
        } catch (ex) {
            console.log('cant verify token: ' + ex);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
