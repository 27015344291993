import { helper as $h } from '@/utils/helper';

const state = () => {
    return {
        menu: [
            {
                icon: 'ThumbsUpIcon', // Иконка для сабменю
                pageName: 'reviews', // Берет из роутера имя и подставляет его PATH
                title: 'Отзывы', // Название отображения
            },
            {
                icon: 'TvIcon',
                pageName: 'news',
                title: 'Новости',
            },
            {
                icon: 'DribbbleIcon',
                pageName: 'trainers',
                title: 'Тренеры',
            },
            {
                icon: 'TwitchIcon',
                pageName: 'faq',
                title: 'FAQ',
            },
            {
                icon: 'FolderIcon',
                pageName: 'affiliates',
                title: 'Филиалы',
            },
            {
                icon: 'GridIcon',
                pageName: 'products',
                title: 'Абонементы',
            },
            {
                icon: 'DollarSignIcon',
                pageName: 'payment',
                title: 'Список платежей',
            },
            {
                icon: 'MailIcon',
                pageName: 'pleas',
                title: 'Список заявок',
            },
            {
                icon: 'UsersIcon',
                pageName: 'users',
                title: 'Пользователи',
            },
        ],
        formattedMenu: null,
    };
};

// getters
const getters = {
    menu: (state) => state.menu,
    formattedMenu: (state) => state.formattedMenu,
};

// actions
const actions = {
    formatMenu({ commit, getters }, { userRules, router }) {
        commit('setFormattedMenu', $h.allowedMenu(getters.menu, userRules, router));
    },
};

// mutations
const mutations = {
    setMenu(state, payload) {
        state.menu = payload;
    },
    clearMenuState(state) {
        state.menu = null;
    },
    setFormattedMenu(state, payload) {
        state.formattedMenu = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
