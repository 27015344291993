<template>
  <div>
    <div class="container">
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="#"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium">403</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            Произошла ошибка.
          </div>
          <div class="intro-x text-lg mt-3">
            У вас нет доступа к этой странице
          </div>

          <router-link
            :to="{ name: 'home' }"
            class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
          >
            Back to Home
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted} from 'vue';

export default defineComponent({
    setup() {
      onMounted(() => {
        cash('body')
          .removeClass('main')
          .removeClass('login')
          .addClass('error-page');
      });
    },
  });
</script>
