import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import SideMenu from '@/layouts/side-menu/Main.vue';
import Login from '@/views/login/Main.vue';
import ErrorPage from '@/views/error-page/Main.vue';
import AccessDenied from '@/views/access-denied/Main.vue';

const routes = [
    {
        path: '/',
        component: SideMenu,
        meta: {
            auth: ['super-admin', 'affiliate-admin'],
        },
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Main.vue'),
            },

            // --- users
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '@/views/users/Main.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            {
                path: '/users/create',
                name: 'userCreate',
                component: () => import(/* webpackChunkName: "user-create" */ '@/views/users/Create.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            {
                path: '/users/:id/edit',
                name: 'userEdit',
                component: () => import(/* webpackChunkName: "user-edit" */ '@/views/users/Edit.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            // --- users

            // --- news
            {
                path: '/news',
                name: 'news',
                component: () => import(/* webpackChunkName: "news" */ '@/views/news/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/news/create',
                name: 'newsCreate',
                component: () => import(/* webpackChunkName: "news-create" */ '@/views/news/Create.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/news/:id/edit',
                name: 'newsEdit',
                component: () => import(/* webpackChunkName: "news-edit" */ '@/views/news/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- news

            // --- reviews
            {
                path: '/reviews',
                name: 'reviews',
                component: () => import(/* webpackChunkName: "reviews" */ '@/views/reviews/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/reviews/create',
                name: 'reviewsCreate',
                component: () => import(/* webpackChunkName: "reviews-create" */ '@/views/reviews/Create.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/reviews/:id/edit',
                name: 'reviewsEdit',
                component: () => import(/* webpackChunkName: "reviews-edit" */ '@/views/reviews/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- reviews

            // --- affiliates
            {
                path: '/affiliates',
                name: 'affiliates',
                component: () => import(/* webpackChunkName: "affiliates" */ '@/views/affiliates/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/affiliates/create',
                name: 'affiliatesCreate',
                component: () => import(/* webpackChunkName: "affiliates-create" */ '@/views/affiliates/Create.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            {
                path: '/affiliates/:id/edit',
                name: 'affiliatesEdit',
                component: () => import(/* webpackChunkName: "affiliates-edit" */ '@/views/affiliates/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- affiliates

            // --- pleas
            {
                path: '/pleas',
                name: 'pleas',
                component: () => import(/* webpackChunkName: "pleas" */ '@/views/pleas/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- pleas

            // --- payment
            {
                path: '/payment',
                name: 'payment',
                component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Main.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            // --- payment

            // --- products
            {
                path: '/products',
                name: 'products',
                component: () => import(/* webpackChunkName: "products" */ '@/views/products/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/products/create',
                name: 'productsCreate',
                component: () => import(/* webpackChunkName: "products-create" */ '@/views/products/Create.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/products/:id/edit',
                name: 'productsEdit',
                component: () => import(/* webpackChunkName: "products-edit" */ '@/views/products/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- prices

            // --- faq
            {
                path: '/faq',
                name: 'faq',
                component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/Main.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            {
                path: '/faq/create',
                name: 'faqCreate',
                component: () => import(/* webpackChunkName: "faq-create" */ '@/views/faq/Create.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            {
                path: '/faq/:id/edit',
                name: 'faqEdit',
                component: () => import(/* webpackChunkName: "faq-edit" */ '@/views/faq/Edit.vue'),
                meta: {
                    auth: ['super-admin'],
                },
            },
            // --- faq

            // --- trainers
            {
                path: '/trainers',
                name: 'trainers',
                component: () => import(/* webpackChunkName: "trainers" */ '@/views/trainers/Main.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/trainers/create',
                name: 'trainersCreate',
                component: () => import(/* webpackChunkName: "trainers-create" */ '@/views/trainers/Create.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            {
                path: '/trainers/:id/edit',
                name: 'trainersEdit',
                component: () => import(/* webpackChunkName: "trainers-edit" */ '@/views/trainers/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'affiliate-admin'],
                },
            },
            // --- trainers

            // тут можно посмотреть иконки
            // {
            //     path: '/original/icon',
            //     name: 'icons',
            //     component: () => import(/* webpackChunkName: "icon" */ '@/views/original/icon/Main.vue'),
            //     meta: {
            //         auth: ['super-admin', 'affiliate-admin'],
            //     },
            // },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/error-page',
        name: 'error-page',
        component: ErrorPage,
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: AccessDenied,
    },
    {
        path: '/:pathMatch(.*)*',
        component: ErrorPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // общедоступные роуты
    const allowed_routes = ['/login', '/:pathMatch(.*)*', '/error-page'];
    if (allowed_routes.includes(to.path)) {
        return next();
    }

    const token = localStorage.getItem('access_token');
    const currentUser = store.getters['user/isAuthenticated'];

    // если токена нет, кидаем на авторизацию
    if (!token) {
        return next({ path: '/login' });
    }

    if (!currentUser) {
        try {
            await store.dispatch('user/verifyToken');
        } catch (ex) {
            console.error('cant verify user by token: ' + ex);
        }
    }

    // получение ролей
    const userAccessRule = store.getters['user/getUserRole'];
    const { auth: pageRoles } = to.meta;

    if (pageRoles && pageRoles.length > 0) {
        if (pageRoles.includes(userAccessRule)) {
            return next();
        }

        console.error('ACCESS RULES REJECTED');
        return next({ name: 'error-page' });
    }

    next();
});

export default router;
