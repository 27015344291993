import axios from 'axios';
import { router } from '../main';

function validate(data, type) {
    switch (type) {
        case 'email':
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(data);
        case 'password':
            return true;
    }
    return false;
}

async function login({ state, commit, getters }, payload) {
    const payloadKeys = Object.keys(payload);
    payloadKeys.forEach((key) => {
        validate(payload[key], key);
    });
    const { data } = await axios.post('/login', {
        ...payload,
    });
    if (!data.user_token) throw new Error('user not found');
    return { user: data.user, token: data.user_token };
}

async function verifyToken({ commit }) {
    const { data } = await axios.get('/profile');

    if (data) {
        return data;
    } else {
        commit('clearTokens');
        throw new Error('response without user data');
    }
}

async function logout(commit) {
    const logout = await axios.post('/logout');
    if (!logout.status >= 200 && !logout.status < 300) {
        console.log('cant logout: ', logout.status);
        return;
    }

    commit('clearUserData');
    commit('clearTokens');
}

export default {
    login,
    verifyToken,
    logout,
};
